<template>
    <layout>
        <v-row 
            align="center"
            justify="center"
        > 
            <v-card                
                width="500"
            >
                <v-card-title :class="cardTitleClass">
                    OTHER DATA
                </v-card-title>
            </v-card>
        </v-row>

        <v-row 
            align="center"
            justify="center"
            v-for="field in section"
            :key="field.label"            
        > 
            <v-card
                class="pa-6 mt-4" 
                width="500"
                v-if="field.type == 'text'"
            >
                <v-row 
                    align="center"
                    justify="center"                   
                >
                    <v-col
                        cols="12"                        
                    >
                        <v-text-field
                           :label="field.label"
                           v-model="studentRecord[field.model]"
                           :prepend-icon="field.icon"
                           @blur="validate(field.model)"                           
                           autocomplete="false"
                        >
                            <template v-slot:append>
                                <v-fade-transition>
                                    <v-progress-circular
                                        v-if="field.save"
                                        size="16"
                                        width="3"
                                        color="info"
                                        indeterminate
                                    ></v-progress-circular>
                                </v-fade-transition>

                                <v-fade-transition>    
                                    <v-icon
                                        v-if="field.saveSuccess"
                                        small
                                        color="info"                                        
                                    >
                                        mdi-check-all
                                    </v-icon>
                                </v-fade-transition>

                                <v-fade-transition>    
                                    <v-icon
                                        v-if="field.saveError"
                                        small
                                        color="red"                                        
                                    >
                                        mdi-alert-circle
                                    </v-icon>
                                </v-fade-transition>
                            </template>
                        
                        </v-text-field>
                    </v-col>
                </v-row>
            </v-card>

            <v-card
                class="pa-6 mt-4" 
                width="500"
                v-if="field.type == 'select'"
            >
                <v-row 
                    align="center"
                    justify="center"                   
                >
                    <v-col
                        cols="12"                        
                    >
                        <v-select
                            :items="field.options"
                            :label="field.label"
                            v-model="studentRecord[field.model]"
                            @change="validate(field.model)"                            
                            autocomplete="false"
                            :prepend-icon="field.icon"
                        >
                            <template v-slot:append>
                                <v-fade-transition>
                                    <v-progress-circular
                                        v-if="field.save"
                                        size="16"
                                        width="3"
                                        color="info"
                                        indeterminate
                                    ></v-progress-circular>
                                </v-fade-transition>

                                <v-fade-transition>    
                                    <v-icon
                                        v-if="field.saveSuccess"
                                        small
                                        color="info"                                        
                                    >
                                        mdi-check-all
                                    </v-icon>
                                </v-fade-transition>

                                <v-fade-transition>    
                                    <v-icon
                                        v-if="field.saveError"
                                        small
                                        color="red"                                        
                                    >
                                        mdi-alert-circle
                                    </v-icon>
                                </v-fade-transition>
                            </template>
                        </v-select>
                    </v-col>
                </v-row>
            </v-card>
        </v-row>          

        <v-row 
            align="center"
            justify="center"
        > 
            <v-card
                class="pa-6 mt-4" 
                width="500"
            >                 
                <v-row 
                    align="center"
                    justify="center"
                    v-for="field in filterSwitchFields"
                    :key="field.label"
                >
                    <v-col cols="8">
                        {{ field.label }}
                    </v-col>

                    <v-col
                       class="d-flex"
                       v-if="field.type == 'switch'"
                       cols="4"                    
                    >
                        <v-switch
                            v-model="switchFields[field.model]"
                            :label="setLabel(switchFields[field.model])"
                            @change="switchFieldsUpdate(field.model)"
                        >
                            <template v-slot:append>
                                <v-fade-transition>
                                    <v-progress-circular
                                        v-if="field.save"
                                        size="16"
                                        width="3"
                                        color="info"
                                        indeterminate
                                    ></v-progress-circular>
                                </v-fade-transition>

                                <v-fade-transition>    
                                    <v-icon
                                        v-if="field.saveSuccess"
                                        small
                                        color="info"                                        
                                    >
                                        mdi-check-all
                                    </v-icon>
                                </v-fade-transition>

                                <v-fade-transition>    
                                    <v-icon
                                        v-if="field.saveError"
                                        small
                                        color="red"                                        
                                    >
                                        mdi-alert-circle
                                    </v-icon>
                                </v-fade-transition>
                            </template>
                        </v-switch>
                    </v-col>                    
                </v-row>
            </v-card>                      
         </v-row>         
                 
    </layout>    
</template>

<script>
import Layout from './layouts/Registration';
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
    name: 'OtherData',
    components: {
        Layout,
    },

    mounted: function () {
        this.setStudentRecord();
        this.setSwitchFields();
        this.otherDataFields();        
    },
    
    data: () => ({
        fields: [
            {
                label: 'Special Considerations', 
                icon: 'mdi-human-wheelchair', 
                type: 'text', 
                model: 'special_consideration', 
                required: false,
                save: false,
                saveSuccess: false,
                saveError: false,
            },
            {
                label: 'Extra-Curricular Interests', 
                icon: 'mdi-basketball', 
                type: 'text', 
                model: 'achievements', 
                required: false,
                save: false,
                saveSuccess: false,
                saveError: false,
            },
            {
                label: 'What type of device does your child have access to?', 
                options: ['None ', 'Phone', 'Laptop', 'Desktop', 'Tablet', 'Other'],
                type: 'select',
                model: 'device_type',
                icon: 'mdi-laptop',
                required: false,
                save: false,
                saveSuccess: false,
                saveError: false,
            },
            {
                label: 'Does your child have internet access?', 
                type: 'switch', 
                model: 'internet_access',
                required: false,
                save: false,
                saveSuccess: false,
                saveError: false,
            },
            {
                label: 'Would your child require School Feeding Meals?', 
                type: 'switch', 
                model: 'school_feeding',
                required: false,
                save: false,
                saveSuccess: false,
                saveError: false,
            },
            // {
            //     label: 'Does your child access Social Welfare Grant?', 
            //     type: 'switch', 
            //     model: 'social_welfare',
            //     required: false,
            // },
            {
                label: 'Does your child access School Transport?', 
                type: 'switch', 
                model: 'transport',
                required: false,
                save: false,
                saveSuccess: false,
                saveError: false,
            },                        
            // {
            //     label: 'Would you be requesting a transfer?', 
            //     type: 'switch', 
            //     model: 'request_transfer',
            //     required: false
            // },            
        ],
        studentRecord: {},
        switchFields: {
            school_feeding: false,
            //social_welfare: false,
            transport: false,
            internet_access: false,
            //request_transfer: false,
        },
        menu: false,
        menuDateOfBaptism: false,
        dateSave: false,
        dateSaveSuccess: false,
        dateSaveError: false,
        dateSaveBaptism: false,
        dateSaveBaptismSuccess: false,
        dateSaveBaptismError: false,
    }),

    watch: {
        menu (val) {
            console.log(this.$refs);
            val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'));
        },
        
        menuDateOfBaptism (val) {
            val && setTimeout (() => (this.$refs.pickerDateOfBaptism.activePicker = 'YEAR'));
        }
    },

    computed: {
        section(){
            return this.fields.filter( field => field.type == 'text' || field.type == 'select');
        },
        filterSwitchFields(){
            return this.fields.filter( field => field.type == 'switch');
        },
        ...mapGetters({
            primaryColor: 'form/getPrimaryColor',
            colorDarken: 'form/getColorDarken',
        }),
        themeColor(){          
          return this.primaryColor + ' ' + this.colorDarken;
        },
        cardTitleClass(){
            return "secondary--text pl-4 " + this.themeColor;
        },         
    },

    methods: {
        ...mapGetters({
            getStudentRecord: 'auth/getStudentRecord'
        }),

        setStudentRecord(){
            this.studentRecord = {...this.getStudentRecord()}
            //this.studentRecord.date_of_first_communion = this.$date(new Date(this.studentRecord.date_of_first_communion), 'yyyy-MM-dd')
            //this.studentRecord.date_of_baptism = this.$date(new Date(this.studentRecord.date_of_baptism), 'yyyy-MM-dd')
        },

        setSwitchFields(){
            for(const [key] of Object.entries(this.switchFields)){
                //console.log(value);               
               if(this.studentRecord[key] == 1){
                   this.switchFields[key] = true;
               }
               else{
                   this.switchFields[key] = false;
               }                           
           }
           //console.log(this.switchFields);
        },
        ...mapMutations({
            updateStudentRecord: 'auth/setStudentRecord',
            setOtherDataValidated: 'form/setOtherDataValidated',
            setValidationErrors: 'form/setValidationErrors',
            setOtherDataFields: 'form/setOtherDataFields',            
        }),

        ...mapActions({
            saveRecord: 'auth/saveRecord',
        }),

        async updateStore(model){
            //console.log('updating store');
            this.setSaveStatus(model);
            this.updateStudentRecord(this.studentRecord);
            //console.log(this.studentRecord);
            try {
                let response = await this.saveRecord();
                console.log(response.status);
                this.setSaveStatus(model, 'saved');
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
                this.setSaveStatus(model, 'error');
            }           
        },

        switchFieldsUpdate(model){
           for(const [key, value] of Object.entries(this.switchFields)){
               //console.log(`${key}: ${value}`)
               console.log(value);
               if(value){
                   this.studentRecord[key] = 1;
               }
               else{
                   this.studentRecord[key] = 0;
               }
           }
           this.updateStore(model);
        },
        setLabel(label){
            return label ? 'Yes' : 'No';
        },
        validate(model){
            //console.log('Validating...');
            this.updateStore(model);
        },
        otherDataFields(){
            this.setOtherDataFields([...this.fields]);
        },

        saveDate (model) {
            if(model == 'date_of_baptism'){
                this.$refs.menuDateOfBaptism.save();               
            }
            else if (model == 'date_of_first_communion'){
                this.$refs.menu.save();
            }
            
            this.updateStore(model);
        },

        setSaveStatus (model, status = 'saving') {
            //console.log(index);
            //console.log(this.dataFields[index-1]);
            if(model == 'date_of_first_communion'){
                switch (status) {
                    case 'saving':
                        this.dateSaveSuccess = false;
                        this.dateSaveError = false;
                        return new Promise(resolve => {
                            setTimeout(() => {
                                this.dateSave = true;                                                                      
                                resolve(status);
                            }, 600)
                        })

                    case 'saved':
                        this.dateSave = false;
                        this.dateSaveError = false;
                        return new Promise(resolve => {
                            setTimeout(() => {
                                this.dateSaveSuccess = true;                                                                      
                                resolve(status);
                            }, 600)
                        })

                    case 'error':
                        this.dateSave = false;
                        this.dateSaveSuccess = false;
                        return new Promise(resolve => {
                            setTimeout(() => {
                                this.dateSaveError = true;                                                                      
                                resolve(status);
                            }, 600)
                        }) 
                }
            }
            else if(model == 'date_of_baptism') {
                switch (status) {
                    case 'saving':
                        this.dateSaveBaptismSuccess = false;
                        this.dateSaveBaptismError = false;
                        return new Promise(resolve => {
                            setTimeout(() => {
                                this.dateSaveBaptism = true;                                                                      
                                resolve(status);
                            }, 600)
                        })

                    case 'saved':
                        this.dateSaveBaptism = false;
                        this.dateSaveBaptismError = false;
                        return new Promise(resolve => {
                            setTimeout(() => {
                                this.dateSaveBaptismSuccess = true;                                                                      
                                resolve(status);
                            }, 600)
                        })

                    case 'error':
                        this.dateSaveBaptism = false;
                        this.dateSaveBaptismSuccess = false;
                        return new Promise(resolve => {
                            setTimeout(() => {
                                this.dateSaveBaptismError = true;                                                                      
                                resolve(status);
                            }, 600)
                        }) 
                }
            }
            else{            
                for(const field of this.fields) {                
                    if(field.model == model){                    
                        switch (status){
                            case 'saving':                            
                                field.saveSuccess = false;
                                field.saveError = false;                            
                                return new Promise(resolve => {
                                    setTimeout(() => {
                                        field.save = true;                                                                      
                                        resolve(status);
                                    }, 600)
                                })                            
                                
                            case 'saved':
                                field.save = false;
                                field.saveError = false;
                                return new Promise(resolve => {
                                    setTimeout(() => {
                                        field.saveSuccess = true; 
                                        resolve(status);
                                    }, 600)
                                })
                                
                            case 'error':
                                field.save = false;
                                field.saveSuccess = false;
                                return new Promise(resolve => {
                                    setTimeout(() => {
                                        field.saveError = true;
                                        resolve(status);
                                    }, 600)
                                })
                                
                            default:
                                console.log('no match found');
                                return new Promise(resolve => {
                                    resolve('no match');
                                });             
                        }                    
                    }
                }
            }
        }    
    }
}
</script>