<template>
    <layout>
        <v-row 
            align="center"
            justify="center"
        >
            <v-snackbar
                v-model="displayPasswordChanged"            
                elevation="10"
                top            
            >
                Password Changed Successfully.
                <template
                    v-slot:action="{ attrs }"
                >
                    <v-btn
                        color="red"
                        text
                        v-bind="attrs"
                        @click="setDisplayPasswordChanged(false)"
                    >close</v-btn>
                </template>
            </v-snackbar>
            <v-card            
                width="500"
            >  
                <v-card-title class="secondary--text pl-4 primary">
                    STUDENT INFORMATION
                </v-card-title>
                
            </v-card>
        </v-row>
        <v-row
            align="center"
            justify="center"
            v-for="section in sections"
            :key="section.label"            
        >
            <v-card
                class="pa-6 mt-5" 
                width="500"
                v-if="section.display"
            >
                <v-row 
                    align="center"
                    justify="center"
                    v-for="field in sectionFields(section.number)"
                    :key="field.label"
                >
                    <v-col
                        cols="12"                       
                    >                       

                        <v-combobox
                            v-if="field.type == 'select' && field.label == 'Town' && field.required"
                            v-bind:label="field.label"
                            v-model="studentRecord[field.model]"
                            :items="field.items"                            
                            @blur="validateSection(field.model)"
                            chips
                            autocomplete="none"
                            :prepend-icon="field.icon"
                            :placholder="field.placeholder"
                            :rules="[()=> !!studentRecord[field.model] || 'This field is required.']"
                            :ref="field.ref"
                        >
                            <template v-slot:append>
                                <v-fade-transition>
                                    <v-progress-circular
                                        v-if="field.save"
                                        size="16"
                                        width="3"
                                        color="info"
                                        indeterminate
                                    ></v-progress-circular>
                                </v-fade-transition>

                                <v-fade-transition>    
                                    <v-icon
                                        v-if="field.saveSuccess"
                                        small
                                        color="info"                                        
                                    >
                                        mdi-check-all
                                    </v-icon>
                                </v-fade-transition>

                                <v-fade-transition>    
                                    <v-icon
                                        v-if="field.saveError"
                                        small
                                        color="red"                                        
                                    >
                                        mdi-alert-circle
                                    </v-icon>
                                </v-fade-transition>
                            </template>
                        </v-combobox>

                        <v-combobox
                            v-if="field.type == 'select' && field.label == 'Town'"
                            v-bind:label="field.label"
                            v-model="studentRecord[field.model]"
                            :items="field.items"
                            :item-text="field.text"
                            :item-value="field.value"                             
                            @blur="validateSection(field.model)"
                            autocomplete="none"
                            :prepend-icon="field.icon"
                            :placholder="field.placeholder"                            
                            :ref="field.ref"
                            :menu-props="{ top:false, offsetY: false}"
                            counter
                            :maxlength="field.maxlength"  
                        >
                            <template v-slot:append>
                                <v-fade-transition>
                                    <v-progress-circular
                                        v-if="field.save"
                                        size="16"
                                        width="3"
                                        color="info"
                                        indeterminate
                                    ></v-progress-circular>
                                </v-fade-transition>

                                <v-fade-transition>    
                                    <v-icon
                                        v-if="field.saveSuccess"
                                        small
                                        color="info"                                        
                                    >
                                        mdi-check-all
                                    </v-icon>
                                </v-fade-transition>

                                <v-fade-transition>    
                                    <v-icon
                                        v-if="field.saveError"
                                        small
                                        color="red"                                        
                                    >
                                        mdi-alert-circle
                                    </v-icon>
                                </v-fade-transition>
                            </template>
                        </v-combobox>
                    
                        <v-autocomplete
                            v-else-if="field.type == 'select' && field.required"
                            v-bind:label="field.label"
                            v-model="studentRecord[field.model]"
                            :items="field.items"
                            :item-text="field.text"
                            :item-value="field.value"                        
                            @change="validateSection(field.model)"
                            chips
                            autocomplete="false"
                            :prepend-icon="field.icon"
                            :placholder="field.placeholder"
                            :disabled="field.disabled"
                            :filled="field.filled"
                            :rules="[()=> !!studentRecord[field.model] || 'This field is required.']"
                            :ref="field.ref"
                            :menu-props="{ top:false, offsetY: false}"                            
                        >
                            <template v-slot:append>
                                <v-fade-transition>
                                    <v-progress-circular
                                        v-if="field.save"
                                        size="16"
                                        width="3"
                                        color="info"
                                        indeterminate
                                    ></v-progress-circular>
                                </v-fade-transition>

                                <v-fade-transition>    
                                    <v-icon
                                        v-if="field.saveSuccess"
                                        small
                                        color="info"                                        
                                    >
                                        mdi-check-all
                                    </v-icon>
                                </v-fade-transition>

                                <v-fade-transition>    
                                    <v-icon
                                        v-if="field.saveError"
                                        small
                                        color="red"                                        
                                    >
                                        mdi-alert-circle
                                    </v-icon>
                                </v-fade-transition>
                            </template>
                        </v-autocomplete>

                        <v-autocomplete
                            v-else-if="field.type == 'select'"
                            v-bind:label="field.label"
                            v-model="studentRecord[field.model]"
                            :items="field.items"
                            :item-text="field.text"
                            :item-value="field.value"                        
                            @change="validateSection(field.model)"
                            chips
                            color="purple"
                            autocomplete="false"
                            :prepend-icon="field.icon"
                            :placholder="field.placeholder"
                            :disabled="field.disabled"
                            :filled="field.filled"                            
                            :ref="field.ref"
                            :menu-props="{ top:false, offsetY: false}"                                                       
                        >
                            <template v-slot:append>
                                <v-fade-transition>
                                    <v-progress-circular
                                        v-if="field.save"
                                        size="16"
                                        width="3"
                                        color="info"
                                        indeterminate
                                    ></v-progress-circular>
                                </v-fade-transition>

                                <v-fade-transition>    
                                    <v-icon
                                        v-if="field.saveSuccess"
                                        small
                                        color="info"                                        
                                    >
                                        mdi-check-all
                                    </v-icon>
                                </v-fade-transition>

                                <v-fade-transition>    
                                    <v-icon
                                        v-if="field.saveError"
                                        small
                                        color="red"                                        
                                    >
                                        mdi-alert-circle
                                    </v-icon>
                                </v-fade-transition>
                            </template>
                        </v-autocomplete>
                   
                        <v-row
                            v-else-if="field.type == 'switch'"
                            align="center"
                            justify="center"                            
                        >
                            <v-col cols="9">
                                {{ field.label }}
                            </v-col>

                            <v-col
                                class="d-flex"                        
                                cols="3"                    
                            >
                                <v-switch
                                    v-model="immigrationPermit"
                                    :label="setLabel(immigrationPermit)"
                                    @change="toggleImmigration"
                                    :ref="field.ref"                                    
                                >
                                    <template v-slot:append>
                                        <v-fade-transition>
                                            <v-progress-circular
                                                v-if="field.save"
                                                size="16"
                                                width="3"
                                                color="info"
                                                indeterminate
                                            ></v-progress-circular>
                                        </v-fade-transition>

                                        <v-fade-transition>    
                                            <v-icon
                                                v-if="field.saveSuccess"
                                                small
                                                color="info"                                        
                                            >
                                                mdi-check-all
                                            </v-icon>
                                        </v-fade-transition>

                                        <v-fade-transition>    
                                            <v-icon
                                                v-if="field.saveError"
                                                small
                                                color="red"                                        
                                            >
                                                mdi-alert-circle
                                            </v-icon>
                                        </v-fade-transition>
                                    </template>
                                </v-switch>
                            </v-col>
                        </v-row> 

                        <v-text-field
                            v-else-if="field.type == 'phone-number'"
                            v-bind:label="field.label"
                            v-model="studentRecord[field.model]"
                            @blur="validateSection(field.model)"
                            @keypress="numberOnly"
                            :prepend-icon="field.icon"
                            placeholder=" "
                            hint="Enter 7 digits only"
                            persistent-hint
                            prefix="1868"
                            maxlength="7"
                            autocomplete="none"
                            :ref='field.ref'
                            :rules="[() => studentRecord[field.model] && studentRecord[field.model].length == 7 || '7 Digits Needed']"                                                  
                        >
                            <template v-slot:append>
                                <v-fade-transition>
                                    <v-progress-circular
                                        v-if="field.save"
                                        size="16"
                                        width="3"
                                        color="info"
                                        indeterminate
                                    ></v-progress-circular>
                                </v-fade-transition>

                                <v-fade-transition>    
                                    <v-icon
                                        v-if="field.saveSuccess"
                                        small
                                        color="info"                                        
                                    >
                                        mdi-check-all
                                    </v-icon>
                                </v-fade-transition>

                                <v-fade-transition>    
                                    <v-icon
                                        v-if="field.saveError"
                                        small
                                        color="red"                                        
                                    >
                                        mdi-alert-circle
                                    </v-icon>
                                </v-fade-transition>
                            </template>
                        </v-text-field>
                                          
                        <v-menu
                            v-else-if="field.type == 'date'"
                            ref="menuBirthDate"
                            v-model="calendarMenu[field.modelMenu]"
                            :close-on-content-click="false"                           
                            transition="scale-transistion"
                            offset-y
                            min-width="290px"                            
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="studentRecord[field.model]"
                                    :label="field.label"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    autocomplete="false"
                                    :ref='field.ref'                                    
                                    :rules="[()=> !!studentRecord[field.model] || 'This field is required.']"
                                >
                                    <template v-slot:append>
                                        <v-fade-transition>
                                            <v-progress-circular
                                                v-if="field.save"
                                                size="16"
                                                width="3"
                                                color="info"
                                                indeterminate
                                            ></v-progress-circular>
                                        </v-fade-transition>

                                        <v-fade-transition>    
                                            <v-icon
                                                v-if="field.saveSuccess"
                                                small
                                                color="info"                                        
                                            >
                                                mdi-check-all
                                            </v-icon>
                                        </v-fade-transition>

                                        <v-fade-transition>    
                                            <v-icon
                                                v-if="field.saveError"
                                                small
                                                color="red"                                        
                                            >
                                                mdi-alert-circle
                                            </v-icon>
                                        </v-fade-transition>
                                    </template>
                                </v-text-field>
                            </template>
                            <v-date-picker
                                ref="picker"
                                v-model="studentRecord[field.model]"                                
                                @input="setDate(field.label, field.model)"
                                :max="new Date().toISOString().substr(0, 10)"
                            >                                
                            </v-date-picker>                            
                        </v-menu>

                        <v-text-field
                            v-else-if="field.required && field.label == 'Email'"
                            v-bind:label="field.label"
                            v-model="studentRecord[field.model]"
                            @blur="validateSection(field.model)"
                            :prepend-icon="field.icon"                            
                            autocomplete="false"
                            :ref="field.ref"
                            :disabled="field.disabled"
                            :filled="field.filled"
                            counter
                            :maxlength="field.maxlength" 
                            :rules="[
                                () => !!studentRecord[field.model] || 'This field is required.', () => regex.test(studentRecord[field.model]) || 'Invalid e-mail' ]"                           
                        >
                            <template v-slot:append>
                                <v-fade-transition>
                                    <v-progress-circular
                                        v-if="field.save"
                                        size="16"
                                        width="3"
                                        color="info"
                                        indeterminate
                                    ></v-progress-circular>
                                </v-fade-transition>

                                <v-fade-transition>    
                                    <v-icon
                                        v-if="field.saveSuccess"
                                        small
                                        color="info"                                        
                                    >
                                        mdi-check-all
                                    </v-icon>
                                </v-fade-transition>

                                <v-fade-transition>    
                                    <v-icon
                                        v-if="field.saveError"
                                        small
                                        color="red"                                        
                                    >
                                        mdi-alert-circle
                                    </v-icon>
                                </v-fade-transition>
                            </template>
                        </v-text-field>

                        <v-text-field
                            v-else-if="field.label == 'Email'"
                            v-bind:label="field.label"
                            v-model="studentRecord[field.model]"
                            @blur="validateSection(field.model)"
                            :prepend-icon="field.icon"                            
                            autocomplete="false"
                            counter
                            :maxlength="field.maxlength" 
                            :ref="field.ref" 
                            :rules="[() => regex.test(studentRecord[field.model]) || 'Invalid e-mail' ]"                           
                        >
                            <template v-slot:append>
                                <v-fade-transition>
                                    <v-progress-circular
                                        v-if="field.save"
                                        size="16"
                                        width="3"
                                        color="info"
                                        indeterminate
                                    ></v-progress-circular>
                                </v-fade-transition>

                                <v-fade-transition>    
                                    <v-icon
                                        v-if="field.saveSuccess"
                                        small
                                        color="info"                                        
                                    >
                                        mdi-check-all
                                    </v-icon>
                                </v-fade-transition>

                                <v-fade-transition>    
                                    <v-icon
                                        v-if="field.saveError"
                                        small
                                        color="red"                                        
                                    >
                                        mdi-alert-circle
                                    </v-icon>
                                </v-fade-transition>
                            </template>
                        </v-text-field>

                        <v-text-field
                            v-else-if="field.required"
                            v-bind:label="field.label"
                            v-model="studentRecord[field.model]"
                            @blur="validateSection(field.model)"
                            :prepend-icon="field.icon"                            
                            autocomplete="false"
                            :counter="!field.disabled"
                            :maxlength="field.maxlength" 
                            :ref="field.ref" 
                            :disabled="field.disabled"
                            :filled="field.filled"
                            :rules="[()=> !!studentRecord[field.model] || 'This field is required.']"                           
                        >
                            <template v-slot:append>
                                <v-fade-transition>
                                    <v-progress-circular
                                        v-if="field.save"
                                        size="16"
                                        width="3"
                                        color="info"
                                        indeterminate
                                    ></v-progress-circular>
                                </v-fade-transition>

                                <v-fade-transition>    
                                    <v-icon
                                        v-if="field.saveSuccess"
                                        small
                                        color="info"                                        
                                    >
                                        mdi-check-all
                                    </v-icon>
                                </v-fade-transition>

                                <v-fade-transition>    
                                    <v-icon
                                        v-if="field.saveError"
                                        small
                                        color="red"                                        
                                    >
                                        mdi-alert-circle
                                    </v-icon>
                                </v-fade-transition>
                            </template>
                        </v-text-field>

                        <v-text-field
                            v-else
                            v-bind:label="field.label"
                            v-model="studentRecord[field.model]"
                            @blur="validateSection(field.model)"
                            :prepend-icon="field.icon"                            
                            autocomplete="none"
                            :counter="!field.disabled"
                            :maxlength="field.maxlength" 
                            :disabled="field.disabled"
                            :filled="field.filled"
                            :ref="field.ref"       
                        >
                            <template v-slot:append>
                                <v-fade-transition>
                                    <v-progress-circular
                                        v-if="field.save"
                                        size="16"
                                        width="3"
                                        color="info"
                                        indeterminate
                                    ></v-progress-circular>
                                </v-fade-transition>

                                <v-fade-transition>    
                                    <v-icon
                                        v-if="field.saveSuccess"
                                        small
                                        color="info"                                        
                                    >
                                        mdi-check-all
                                    </v-icon>
                                </v-fade-transition>

                                <v-fade-transition>    
                                    <v-icon
                                        v-if="field.saveError"
                                        small
                                        color="red"                                        
                                    >
                                        mdi-alert-circle
                                    </v-icon>
                                </v-fade-transition>
                            </template>
                        </v-text-field>

                    </v-col>                
                </v-row>            
            </v-card>
        </v-row>

        <v-row
            align="center"
            justify="center"
            v-if="section7"                        
        >
            <v-card
                class="pa-6 mt-5" 
                width="500"                
            >
                <v-row 
                    align="center"
                    justify="center"
                    v-for="field in sectionFields(7)"
                    :key="field.label"
                >
                    <v-col
                        cols="12"                        
                    >
                                                
                        <v-menu
                            v-if="field.type == 'date'"
                            ref="menu"
                            v-model="calendarMenu[field.modelMenu]"
                            :close-on-content-click="false"                           
                            transition="scale-transistion"
                            offset-y
                            min-width="290px"                            
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="studentRecord[field.model]"
                                    :label="field.label"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    autocomplete="false"
                                    :ref='field.ref'                                    
                                >
                                    <template v-slot:append>
                                        <v-fade-transition>
                                            <v-progress-circular
                                                v-if="field.save"
                                                size="16"
                                                width="3"
                                                color="info"
                                                indeterminate
                                            ></v-progress-circular>
                                        </v-fade-transition>

                                        <v-fade-transition>    
                                            <v-icon
                                                v-if="field.saveSuccess"
                                                small
                                                color="info"                                        
                                            >
                                                mdi-check-all
                                            </v-icon>
                                        </v-fade-transition>

                                        <v-fade-transition>    
                                            <v-icon
                                                v-if="field.saveError"
                                                small
                                                color="red"                                        
                                            >
                                                mdi-alert-circle
                                            </v-icon>
                                        </v-fade-transition>
                                    </template>
                                </v-text-field>
                            </template>
                            <v-date-picker
                                ref="picker"
                                v-model="studentRecord[field.model]"                                
                                @change="setDate(field.label, field.model)"
                                :max="new Date().toISOString().substr(0, 10)"
                            >                                
                            </v-date-picker>                            
                        </v-menu>

                        <v-text-field
                            v-else
                            v-bind:label="field.label"
                            v-model="studentRecord[field.model]"
                            @blur="validateSection(field.model)"
                            :prepend-icon="field.icon"
                        >
                            <template v-slot:append>
                                <v-fade-transition>
                                    <v-progress-circular
                                        v-if="field.save"
                                        size="16"
                                        width="3"
                                        color="info"
                                        indeterminate
                                    ></v-progress-circular>
                                </v-fade-transition>

                                <v-fade-transition>    
                                    <v-icon
                                        v-if="field.saveSuccess"
                                        small
                                        color="info"                                        
                                    >
                                        mdi-check-all
                                    </v-icon>
                                </v-fade-transition>

                                <v-fade-transition>    
                                    <v-icon
                                        v-if="field.saveError"
                                        small
                                        color="red"                                        
                                    >
                                        mdi-alert-circle
                                    </v-icon>
                                </v-fade-transition>
                            </template>
                        </v-text-field>
                    </v-col>                    
                </v-row>
            </v-card>                
        </v-row>

    </layout>    
</template>

<script>
import Layout from './layouts/Registration';
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
    name: 'StudentData',
    components: {
        Layout,
    },
    
    data: () => ({        
        fields: [
            { label: 'section 1', break: true, number: 1, display: true},
            {
                label: 'House', 
                model: 'house_code', 
                type: 'select', 
                disabled: true,
                filled: false,
                required: false,
                ref: 'house',
                items: [],
                text: 'name',
                value: 'id',
                section: 1,
                save: false,
                saveSuccess: false,
                saveError: false,
            },
            {
                label: 'Class', 
                model: 'class_id', 
                type: 'text', 
                disabled: true,
                filled: true,
                required: false,
                ref: 'class',
                items: [],
                section: 1,
                save: false,
                saveSuccess: false,
                saveError: false,
            },
            { label: 'section 2', break: true, number: 2, display: true},
            {
                label: 'First Name', 
                model: 'first_name', 
                type: 'text',
                required: false,
                ref: 'first_name',
                section: 2,
                save: false,
                saveSuccess: false,
                saveError: false,
                maxlength: 20,
            },
            {
                label: 'Last Name', 
                model: 'last_name', 
                type: 'text',
                required: false,
                ref: 'last_name', 
                section: 2,
                save: false,
                saveSuccess: false,
                saveError: false,
                maxlength: 20,
            },
            {
                label: 'Other Name', 
                model: 'middle_name', 
                type: 'text',
                required: false,
                ref: 'middle_name', 
                section: 2,
                save: false,
                saveSuccess: false,
                saveError: false,
                maxlength: 20,
            },
            {
                label: 'Gender', 
                model: 'sex', 
                type: 'select',
                items: [{text: 'Female', value: 'Female'}, {text: 'Male', value: 'Male'}],
                required: false,
                ref: 'sex',
                section: 2,
                save: false,
                saveSuccess: false,
                saveError: false,                
            },
            {label: 'section 3', break: true, number: 3, display: true},
            {
                label: 'Address', 
                model: 'home_address', 
                type: 'text', 
                icon: 'mdi-home',
                required: false,
                ref: 'home_address', 
                section: 3,
                save: false,
                saveSuccess: false,
                saveError: false,
                maxlength: 100,
            },
            {
                label: 'Town', 
                model: 'address_line_2', 
                type: 'select',
                items: [],
                text: 'town_name',
                value: 'town_name',                
                placeholder: 'Type to search for town',
                icon: 'mdi-city',
                required: false,
                ref: 'address_line_2',
                section: 3,
                save: false,
                saveSuccess: false,
                saveError: false,
                maxlength: 100,
            },
            {
                label: 'Regional Corporation', 
                model: 'regional_corporation', 
                type: 'select',
                items: [],
                text: 'title',
                value: 'title',                
                placeholder: 'Type to search for regional corporation',
                icon: 'mdi-city',
                required: false,
                ref: 'regional_corporation',
                section: 3,
                save: false,
                saveSuccess: false,
                saveError: false,
            },
            {
                label: 'Phone Number (Mobile)', 
                model: 'phone_home', 
                type: 'phone-number', 
                icon: 'mdi-cellphone',
                required: false,
                section: 3,
                save: false,
                saveSuccess: false,
                saveError: false,
            },
            {
                label: 'Email', 
                model: 'email', 
                type: "email", 
                icon: 'mdi-email',
                required: false,
                section: 3,
                save: false,
                saveSuccess: false,
                saveError: false,
                maxlength: 50,
            },
            {
                label: 'Blood Group', 
                model: 'blood_type', 
                type: "select",
                items: [
                    'O-positive',
                    'O-negative',
                    'A-positive',
                    'A-negative',
                    'B-positive',
                    'B-negative',
                    'AB-positive',
                    'AB-negative'
                ],
                placeholder: 'Type to search for blood group',
                icon: 'mdi-water',
                required: false,                
                section: 3,
                save: false,
                saveSuccess: false,
                saveError: false,
            },
            {label: 'section 4', break: true, number: 4, display: true},
            {
                label: 'Previous School', 
                model: 'previous_school', 
                type: 'text',
                required: false,
                section: 4,
                save: false,
                saveSuccess: false,
                saveError: false,
                maxlength: 50,
            },
            {
                label: 'SEA Number', 
                model: 'sea_no', 
                type: 'text',
                required: false,
                section: 4,
                save: false,
                saveSuccess: false,
                saveError: false,
                maxlength: 15,
            },
            {
                label: 'Religion', 
                model: 'religion_code', 
                type: 'select',
                items: [],
                text: 'religion',
                value: 'code',
                required: false,
                section: 4,
                save: false,
                saveSuccess: false,
                saveError: false,
            },
            {
                label: 'Ethnic Group', 
                model: 'ethnic_group_code', 
                type: 'select',
                items: [],
                text: 'group_type',
                value: 'id',
                required: false,
                section: 4,
                save: false,
                saveSuccess: false,
                saveError: false,
            },
            {label: 'section 5', break: true, number: 5, display: true},            
            {
                label: 'Electronic Birth Pin', 
                model: 'birth_certificate_no', 
                type: 'text', 
                icon: 'mdi-dialpad',
                required: false,
                section: 5,
                save: false,
                saveSuccess: false,
                saveError: false,
                maxlength: 15,
            },            
            {
                label: 'Date of Birth', 
                model: 'date_of_birth',
                modelMenu: 'menuBirthDate',
                type: 'date', 
                icon: 'mdi-calendar',
                required: false,
                section: 5,
                save: false,
                saveSuccess: false,
                saveError: false,
            },            
            {
                label: 'Country of Birth', 
                model: 'place_of_birth', 
                type: 'text', 
                icon: 'mdi-earth',
                required: false,
                section: 5,
                save: false,
                saveSuccess: false,
                saveError: false,
                maxlength: 25,
            },
            {
                label: 'Nationality', 
                model: 'nationality', 
                type: 'text', 
                icon: 'mdi-flag',
                required: false,
                section: 5,
                save: false,
                saveSuccess: false,
                saveError: false,
                maxlength: 25,
            },
            // {label: 'section 6', break: true, number: 6, display: true},           
            // {
            //     label: 'Do you have an Immigration Student Permit', 
            //     model: 'immigration_permit', 
            //     type: 'switch',
            //     required: false,
            //     section: 6,
            // },
            // {label: 'section 7', break: true, number: 7, display: false},   
            // {
            //     label: 'Date of Issue', 
            //     model: 'permit_issue_date',
            //     modelMenu: 'menuIssueDate', 
            //     type: 'date',
            //     required: false,
            //     section: 7,
            //     display: false,
            // },
            // {
            //     label: 'Expiry Date', 
            //     model: 'permit_expiry_date',
            //     modelMenu: 'menuExpiryDate', 
            //     type: 'date',
            //     section: 7,
            //     required: false,
            //     display: false,
            // },            
            // {
            //     label: 'Second Language', 
            //     model: 'second_language', 
            //     type: 'text',
            //     icon: 'mdi-earth',
            //     section: 7,
            //     required: false,
            //     display: false,
            // },
        ],        
        studentRecord: {},
        section7: false,
        immigrationPermit: false,        
        menu: false,        
        calendarMenu: {
            menuBirthDate: false,
            menuIssueDate: false,
            menuExpiryDate: false,
        },
        date: null,
        sectionValid: false,
        rules: {
            email: value => {
                const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                return pattern.test(value) || 'Invalid e-mail.'
            },
            required: value => !!value || 'This field is required.'
        },
        snackbar: true,
        saving: false,
        saved: false,
        saveError: false,        
    }),

    mounted: function () {
        // this.initialize();        
        this.loadTowns();
        this.loadReligions();
        this.loadEthnicGroups();
        this.setStudentDataFields();
        this.loadRegionalCorporations();
        this.setDialog(false);
    },
    
    computed: {        
        ...mapGetters({            
            displayPasswordChanged: 'auth/getDisplayPasswordChanged',
            getStudentRecord: 'auth/getStudentRecord',
        }),
        sections(){
            return this.fields.filter( field => field.break == true);
        },
        regex(){
            return new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/) 
        },
    },

    watch: {
        immigrationPermit: {
            handler(newValue){
                this.section7 = newValue;
            }
        },

        getStudentRecord: {
            handler () {
                this.initialize();
            }
        }
        
    },

    methods: {
        initialize () {
            this.mapStudentRecord();
            this.mapHouses();
        },

        mapStudentRecord () {
            this.studentRecord = {...this.getStudentRecord}
            console.log(this.studentRecord);
            if(this.studentRecord.terms_and_conditions == 0) this.setTermsAndConditions(false);
            else this.setTermsAndConditions(true);
        },

        ...mapMutations({
            updateStudentRecord: 'auth/setStudentRecord',
            setTowns: 'form/setTowns',
            setReligions: 'form/setReligions',
            setEthnicGroups: 'form/setEthnicGroups',
            setStudentDataValidated: 'form/setStudentDataValidated',
            setValidationErrors: 'form/setValidationErrors',
            setUser: 'form/setUser',
            studentDataFields: 'form/setStudentDataFields',
            setTermsAndConditions: 'form/setTermsAndConditions',
            setDialog: 'form/setDialog',
            setDisplayPasswordChanged: 'auth/setDisplayPasswordChanged',            
        }),

        ...mapActions({
            saveRecord: 'auth/saveRecord',
            retrieveTowns: 'form/retrieveTowns',
            retrieveReligions: 'form/retrieveReligions',
            retrieveEthnicGroups: 'form/retrieveEthnicGroups',
            getRegionalCorporations: 'form/getRegionalCorporations',
            getHouses: 'form/getHouses',
        }),

        async updateStore(model){
            let response = null;
            //console.log(this.studentRecord);
            //this.studentRecord.date_of_birth = this.$date(new Date(this.studentRecord.date_of_birth), 'yyyy-MM-dd')                 
            if(this.studentRecord.address_line_2 && this.studentRecord.address_line_2.town_name){
                this.studentRecord.address_line_2 = this.studentRecord.address_line_2.town_name;                
            }
            
            this.updateStudentRecord(this.studentRecord);
            try {
                response = await this.saveRecord();
                console.log(response);
                this.setSaveStatus (model, 'saved'); 
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
                this.setSaveStatus (model, 'error')
            }
        },

        async loadTowns(){
           let index = -1, response = null;
           try {
               response = await this.retrieveTowns();
               this.fields.forEach(field => {
                    if(field.model == 'address_line_2'){
                        index = this.fields.indexOf(field);
                    }
                })
                if(index != -1) this.fields[index].items = response.data;
           } catch (error) {
               if(error.response) console.log(error.response);
               else console.log(error);
           }
        },

        async loadReligions(){
            let index = -1, response = null;
            try {
                response = await this.retrieveReligions();
                this.fields.forEach(field => {
                    if(field.model == 'religion_code'){
                        index = this.fields.indexOf(field);
                    }
                })
                if(index != -1) this.fields[index].items = response.data;
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
            }
        },

        async loadEthnicGroups(){
            let index = -1, response = null;
            try {
                response = await this.retrieveEthnicGroups();
                this.fields.forEach(field => {
                    if(field.model == 'ethnic_group_code'){
                        index = this.fields.indexOf(field);
                    }
                })
                if(index != -1) this.fields[index].items = response.data;
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
            }            
        },

        async loadRegionalCorporations () {
            let index = -1, response = null;
            try {
                response = await this.getRegionalCorporations();

                
                this.fields.forEach(field => {
                    if(field.model == 'regional_corporation'){
                        index = this.fields.indexOf(field);
                    }
                })
                
                if(index != -1) this.fields[index].items = response.data;
                

            } catch (error) {
                if(error.response) console.log(error.response);
                console.log(error);
            }
        },

        async mapHouses () {
            try {
                const { data } = await this.getHouses();
                this.fields.map(value => {
                    if(value.model == 'house_code'){
                        value.items = data;
                    }
                })
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
            }
        },

        sectionFields(number){
            return this.fields.filter( field => field.section == number);
        },

        setLabel(label){
            return label ? 'Yes' : 'No';
        },

        toggleImmigration(){            
            if(this.immigrationPermit){
                this.studentRecord.immigration_permit = 1;
            }
            else{
                this.studentRecord.immigration_permit = 0;
            }
            //this.validateSection(field.model)();
        },

        setDate(menu, model){            
            switch(menu){
                case 'Date of Birth':
                    //this.studentRecord.date_of_birth = this.$date(new Date(this.studentRecord.date_of_birth), 'dd-MM-yyyy') 
                    this.calendarMenu.menuBirthDate = false;                    
                    break;
                case 'Expiry Date':
                    this.calendarMenu.menuExpiryDate = false;
                    break;
                case 'Date of Issue':
                    this.calendarMenu.menuIssueDate = false;
                    break;        
            }                     
            this.validateSection(model)
        },

        async validateSection(model){
            console.log('Validating...');
            await this.setSaveStatus(model);
            this.updateStore(model);
        },

        setStudentDataFields(){
            let sectionFields = this.fields.filter( field => field.break !== true);
            this.studentDataFields(sectionFields);
        },

        closeSnackbar(){
            this.setDisplayPasswordChanged(false);
        },

        numberOnly($event){            
            if(!/\d/.test($event.key)) return $event.preventDefault();
        },

        showSaveAction(){
            this.saving = true;           
            
        },

        hideSaveAction(){
            this.loading = false;
            
        },

        setSaveStatus (model, status = 'saving') {
            //console.log(index);
            //console.log(this.dataFields[index-1]);
            for(const field of this.fields) {                
                if(field.model == model){                    
                    switch (status){
                        case 'saving':                            
                            field.saveSuccess = false;
                            field.saveError = false;                            
                            return new Promise(resolve => {
                                setTimeout(() => {
                                    field.save = true;                                                                      
                                    resolve(status);
                                }, 600)
                            })                            
                            
                        case 'saved':
                            field.save = false;
                            field.saveError = false;
                            return new Promise(resolve => {
                                setTimeout(() => {
                                    field.saveSuccess = true; 
                                    resolve(status);
                                }, 600)
                            })
                            
                        case 'error':
                            field.save = false;
                            field.saveSuccess = false;
                            return new Promise(resolve => {
                                setTimeout(() => {
                                    field.saveError = true;
                                    resolve(status);
                                }, 600)
                            })
                            
                        default:
                            console.log('no match found');
                            return new Promise(resolve => {
                                resolve('no match');
                            });             
                    }                    
                }
            }
                    
        },
              
    },
    
}
</script>