<template>
    <layout>
         <v-row 
            align="center"
            justify="center"
        > 
            <v-card               
                width="500"
            >
                <v-card-title :class="cardTitleClass">
                    HEALTH DATA
                </v-card-title>  
                              
            </v-card>            
         </v-row>

         <v-row 
            align="center"
            justify="center"
            v-if="immunization"
        > 
            <v-card
                class="mt-4 pa-6" 
                width="500"
            >  
                 
                    <v-toolbar-title class="title pl-0">
                        IMMUNIZATION RECORDS
                    </v-toolbar-title>
               
                
                    <v-toolbar-title class="body-2 pl-0 text-wrap mt-5">
                        Please indicate whether your child was immunized for the following
                    </v-toolbar-title>
                
                <v-row 
                    align="center"
                    justify="center"
                    v-for="field in immunizationRecords"
                    :key="field.label"
                                        
                >
                    <v-col
                        cols="12"                        
                    >
                        <v-checkbox
                            v-bind:label="field.label"
                            v-model="healthRecord[field.model]"
                            @change="checkBoxUpdate(immunizationRecords, field.model)"
                            dense
                            hide-details
                        >
                                <template v-slot:append>
                                    <v-fade-transition>
                                        <v-progress-circular
                                            v-if="field.save"
                                            size="16"
                                            width="3"
                                            color="info"
                                            indeterminate
                                        ></v-progress-circular>
                                    </v-fade-transition>

                                    <v-fade-transition>    
                                        <v-icon
                                            v-if="field.saveSuccess"
                                            small
                                            color="info"                                        
                                        >
                                            mdi-check-all
                                        </v-icon>
                                    </v-fade-transition>

                                    <v-fade-transition>    
                                        <v-icon
                                            v-if="field.saveError"
                                            small
                                            color="red"                                        
                                        >
                                            mdi-alert-circle
                                        </v-icon>
                                    </v-fade-transition>
                                </template>
                        </v-checkbox>
                    </v-col>
                </v-row>
            </v-card>                      
         </v-row>        

        <v-row 
            align="center"
            justify="center"
        > 
            <v-card
                    class="mt-4" 
                    width="500"
                >
                <v-card-title :class="cardTitleClass">
                    HEALTH CONDITIONS
                </v-card-title> 
                
                <v-app-bar
                    flat
                    color="white"                    
                >
                    <v-toolbar-title class="body-2 pl-0 text-wrap">                        
                        Please indicate whether your child suffers from any of the following  
                    </v-toolbar-title>
                </v-app-bar>
                <v-row 
                    align="center"
                    justify="center"
                    v-for="field in healthConditions"
                    :key="field.label" 
                    class="px-4"                                         
                >
                    <v-col
                        cols="12"                        
                    >
                        <v-checkbox
                           v-bind:label="field.label"
                            v-model="healthRecord[field.model]"
                            @change="checkBoxUpdate(healthConditions, field.model)"
                            dense
                            hide-details
                        >
                            <template v-slot:append>
                                <v-fade-transition>
                                    <v-progress-circular
                                        v-if="field.save"
                                        size="16"
                                        width="3"
                                        color="info"
                                        indeterminate
                                    ></v-progress-circular>
                                </v-fade-transition>

                                <v-fade-transition>    
                                    <v-icon
                                        v-if="field.saveSuccess"
                                        small
                                        color="info"                                        
                                    >
                                        mdi-check-all
                                    </v-icon>
                                </v-fade-transition>

                                <v-fade-transition>    
                                    <v-icon
                                        v-if="field.saveError"
                                        small
                                        color="red"                                        
                                    >
                                        mdi-alert-circle
                                    </v-icon>
                                </v-fade-transition>
                            </template>
                        
                        </v-checkbox>
                    </v-col>
                </v-row>
            </v-card>
        </v-row>

        <v-row 
            align="center"
            justify="center"
        > 
            <v-card
                class="pa-6 mt-4" 
                width="500"
            >
                <v-row 
                    align="center"
                    justify="center"
                    v-for="field in fields"
                    :key="field.label"
                >
                    <v-col
                        cols="12"                        
                    >
                        <v-text-field
                            v-bind:label="field.label"
                            v-model="studentRecord[field.model]"
                            :placeholder="field.placeholder"
                            @blur="updateStore(fields, field.model)"
                            autocomplete="false"
                        >
                            <template v-slot:append>
                                <v-fade-transition>
                                    <v-progress-circular
                                        v-if="field.save"
                                        size="16"
                                        width="3"
                                        color="info"
                                        indeterminate
                                    ></v-progress-circular>
                                </v-fade-transition>

                                <v-fade-transition>    
                                    <v-icon
                                        v-if="field.saveSuccess"
                                        small
                                        color="info"                                        
                                    >
                                        mdi-check-all
                                    </v-icon>
                                </v-fade-transition>

                                <v-fade-transition>    
                                    <v-icon
                                        v-if="field.saveError"
                                        small
                                        color="red"                                        
                                    >
                                        mdi-alert-circle
                                    </v-icon>
                                </v-fade-transition>
                            </template>
                        </v-text-field>
                    </v-col>
                </v-row>
            </v-card> 
        </v-row>

        <!-- Learning Challenges -->
        <v-row 
            align="center"
            justify="center"
            v-if="learningChallenges"
        > 
            <v-card
                    class="mt-4" 
                    width="500"
                >
                <v-card-title :class="cardTitleClass">
                    LEARNING CHALLENGES
                </v-card-title> 
                

                <v-app-bar
                    flat
                    color="white"
                    class="pa-4 mt-4" 
                >
                    <v-toolbar-title class="body-2 pl-0 text-wrap">
                        Please indicate whether your child has been diagnosed with the following
                    </v-toolbar-title>
                </v-app-bar>    
                
                <v-row 
                    align="center"
                    justify="center"
                    v-for="field in learningDisabilites"
                    :key="field.label"
                    class="pa-6" 
                >
                    <v-col
                        cols="12"                        
                    >
                        <v-checkbox
                           v-bind:label="field.label"
                            v-model="healthRecord[field.model]"
                            @change="checkBoxUpdate"
                        ></v-checkbox>
                    </v-col>
                </v-row>
                <v-row 
                    align="center"
                    justify="center"
                    v-if="otherLearningDisablity"                    
                >
                    <v-col
                        cols="12"                        
                    >
                        <v-text-field                            
                            v-model="studentRecord.other_learning_disablity"
                            placeholder="Please state other learning challenges"
                            @blur="updateStore"
                        ></v-text-field>
                    </v-col>
                </v-row>
            </v-card>
        </v-row>
                           
    </layout>    
</template>

<script>
import Layout from './layouts/Registration';
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
    name: 'HealthData',
    components: {
        Layout,
    },
    data: () => ({
        fields: [
            {
                label: 'Other Health Condition', 
                type: 'text', 
                placeholder: 'State any other health condition', 
                model: 'medical_history',
                save: false,
                saveSuccess: false,
                saveError: false,
            },
            {
                label: 'Serious Illness', 
                type: 'text', 
                placeholder: 'State any serious illness', 
                model: 'other_illness',
                save: false,
                saveSuccess: false,
                saveError: false,
            },
            {
                label: 'Allergies', 
                type: 'text', 
                placeholder: 'State any allergies', 
                model: 'allergy',
                save: false,
                saveSuccess: false,
                saveError: false,
            },
        ],
        immunizationRecords: [
            {
                label: 'Hepatitis', 
                model: 'hepatitis',
                save: false,
                saveSuccess: false,
                saveError: false,
            },
            {
                label: 'Polio', 
                model: 'polio',
                save: false,
                saveSuccess: false,
                saveError: false,
            },
            {
                label: 'Diphtheria', 
                model: 'diphtheria',
                save: false,
                saveSuccess: false,
                saveError: false,
            },
            {
                label: 'Tetanus', 
                model: 'tetanus',
                save: false,
                saveSuccess: false,
                saveError: false,
            },
            {
                label: 'Yellow Fever', 
                model: 'yellow_fever',
                save: false,
                saveSuccess: false,
                saveError: false,
            },
            {
                label: 'Measles', 
                model: 'measles',
                save: false,
                saveSuccess: false,
                saveError: false,
            },
            {
                label: 'T.B.', 
                model: 'tb',
                save: false,
                saveSuccess: false,
                saveError: false,
            },
            {
                label: 'Chicken Pox', 
                model: 'chicken_pox',
                save: false,
                saveSuccess: false,
                saveError: false,
            },
            {
                label: 'Typhoid', 
                model: 'typhoid',
                save: false,
                saveSuccess: false,
                saveError: false,
            },
            {
                label: 'Rheumatic Fever', 
                model: 'rheumatic_fever',
                save: false,
                saveSuccess: false,
                saveError: false,
            },
        ],
        healthConditions: [
            {
                label: 'Poor Eyesight', 
                model: 'poor_eyesight',
                save: false,
                saveSuccess: false,
                saveError: false,
            },
            {
                label: 'Poor Hearing', 
                model: 'poor_hearing',
                save: false,
                saveSuccess: false,
                saveError: false,
            },
            {
                label: 'Diabetes', 
                model: 'diabetes',
                save: false,
                saveSuccess: false,
                saveError: false,
            },
            {
                label: 'Asthma', 
                model: 'asthma',
                save: false,
                saveSuccess: false,
                saveError: false,
            },
            {
                label: 'Epilepsy', 
                model: 'epilepsy',
                save: false,
                saveSuccess: false,
                saveError: false,
            },
            // {label: 'Heart Related Illness', model: 'heart_condition'},
            // {label: 'Skin Disorder', model: 'skin_disorder'},
            // {label: 'Depression', model: 'depression'},
            // {label: 'Anxiety', model: 'anxiety'},            
        ],
        learningDisabilites: [
            {label: 'Dyslexia', model: 'dyslexia'},
            {label: 'Dyscalculia', model: 'dyscalculia'},
            {label: 'Dysgraphia', model: 'dysgraphia'},
            {label: 'Non Verbal learning disorder', model: 'non_verbal'},
            {label: 'Apraxia of speech', model: 'apraxia'},
            {label: 'Attention deficit disorder', model: 'attention_deficit'},
            {label: 'Attention deficit hyperactivity disorder', model: 'adhd'},
            {label: 'Autism', model: 'autism'},
            {label: 'Cerebral palsy', model: 'cerebral_palsy'},
            {label: 'Other', model: 'other'},
        ],
        learningChallenges: false,
        immunization: true,
        studentRecord: {},
        otherLearningDisablity: false,
        otherDisablity: '',       
        healthRecord: {
            hepatitis: false,
            polio: false,
            diphtheria: false,
            tetanus: false,
            yellow_fever: false,
            measles: false,
            tb: false,
            chicken_pox: false,
            typhoid: false,
            rheumatic_fever: false,
            poor_eyesight: false,
            poor_hearing: false,
            diabetes: false,
            asthma: false,
            epilepsy: false,
            // heart_condition: false,
            // skin_disorder: false, 
            // depression: false,
            // anxiety: false,
            // dyslexia: false,
            // dyscalculia: false,
            // dysgraphia: false,
            // non_verbal: false,
            // apraxia: false,
            // attention_deficit: false,
            // adhd: false,
            // autism: false,
            // cerebral_palsy: false,
            // other: false,
        }  
    }),

    mounted: function () {
        this.setStudentRecord();
        this.setHealthRecord();        
    },
    computed: {
        ...mapGetters({
            primaryColor: 'form/getPrimaryColor',
            colorDarken: 'form/getColorDarken',
        }),
        themeColor(){          
            return this.primaryColor + ' ' + this.colorDarken;
        },
        cardTitleClass(){
            return "secondary--text pl-4 " + this.themeColor;
        },    
    },         
    methods: {
        ...mapGetters({
            getStudentRecord: 'auth/getStudentRecord'
        }),
        setStudentRecord(){
            this.studentRecord = {...this.getStudentRecord()}
        },
        setHealthRecord(){
            for(const [key] of Object.entries(this.healthRecord)){
               //console.log(this.studentRecord[key]);
               //console.log(key);
               //console.log(value);
               if(this.studentRecord[key] == 1){
                   this.healthRecord[key] = true;
               }
               else{
                   this.healthRecord[key] = false;
               }                           
           }
           //console.log(this.healthRecord);
        },
        ...mapMutations({
            updateStudentRecord: 'auth/setStudentRecord',            
        }),
        ...mapActions({
            saveRecord: 'auth/saveRecord',
        }),

        async updateStore(array, model){
            //console.log('updating store');
            this.setSaveStatus(array, model);            
            this.updateStudentRecord(this.studentRecord);
            try {
                let response = await this.saveRecord();
                console.log(response.status);
                this.setSaveStatus(array, model, 'saved');
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
                this.setSaveStatus(array, model, 'error');
            }            
        },

        checkBoxUpdate(array, model){            
            for(const [key, value] of Object.entries(this.healthRecord)){
                //console.log(`${key}: ${value}`)
                if(key == 'other'){
                    if(this.healthRecord.other){
                            this.otherLearningDisablity = true;
                    }
                    else{
                        this.otherLearningDisablity = false;
                    }                   
                }
                else if(value){
                    this.studentRecord[key] = 1;
                }
                else{
                    this.studentRecord[key] = 0;
                }
            }
            this.updateStore(array, model);
        },

        setSaveStatus (array, model, status = 'saving') {
            //console.log(index);
            //console.log(this.dataFields[index-1]);
            for(const field of array) {                
                if(field.model == model){                    
                    switch (status){
                        case 'saving':                            
                            field.saveSuccess = false;
                            field.saveError = false;                            
                            return new Promise(resolve => {
                                setTimeout(() => {
                                    field.save = true;                                                                      
                                    resolve(status);
                                }, 600)
                            })                            
                            
                        case 'saved':
                            field.save = false;
                            field.saveError = false;
                            return new Promise(resolve => {
                                setTimeout(() => {
                                    field.saveSuccess = true; 
                                    resolve(status);
                                }, 600)
                            })
                            
                        case 'error':
                            field.save = false;
                            field.saveSuccess = false;
                            return new Promise(resolve => {
                                setTimeout(() => {
                                    field.saveError = true;
                                    resolve(status);
                                }, 600)
                            })
                            
                        default:
                            console.log('no match found');
                            return new Promise(resolve => {
                                resolve('no match');
                            });             
                    }                    
                }
            }
        }    
    }
}
</script>